import React from "react"
import SimplePage from "../components/page/SimplePage"

import { MainBanner } from "../components/home/MainBanner"
import Offers from "../components/offers/Offers"
import { Tips } from "../components/Tips"
import { graphql } from "gatsby"

const OffersPage = ({ data }) => {
  return (
    <SimplePage title="Aktuelle Flaschenteilungen">
      <Offers />
      <Tips page="offers" />
    </SimplePage>
  )
}

export default OffersPage
